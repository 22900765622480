window.cookieConsent = (() => {
    const checkboxes = Array.from(
        document.getElementsByClassName("cookie-checkbox")
    );
    const expandBtns = Array.from(document.getElementsByClassName("expander"));
    const parentCheckboxes = Array.from(
        document.getElementsByClassName("cookie-checkbox-parent")
    );

    const acceptAllBtn = document.getElementById("accept-all");
    const acceptSelectedBtn = document.getElementById("accept-selected");
    const cookieDialog = document.getElementById("cookie-dialog");
    const cookieSettingsToggle = document.getElementById(
        "cookie-settings-toggle"
    );

    acceptAllBtn.addEventListener("click", e => {
        e.preventDefault();

        const selected = checkboxes.map(cb => cb.id);

        setCookie(arrToObject(selected));
        hideDialog();
    });

    acceptSelectedBtn.addEventListener("click", e => {
        e.preventDefault();

        const selected = checkboxes.filter(cb => cb.checked).map(cb => cb.id);

        setCookie(arrToObject(selected));
        hideDialog();
    });

    cookieSettingsToggle.addEventListener("click", e => {
        e.preventDefault();

        showDialog();
    });

    expandBtns.forEach(btn => {
        let ogButtonText = btn.innerHTML;

        btn.addEventListener("click", e => {
            // Prevent default action
            e.preventDefault();

            const id = e.target.dataset.id;
            const panel = document.getElementById(`panel-${id}`);

            if (!panel.style.display || panel.style.display === "none") {
                // Invert button text
                e.target.innerHTML = "Collapse";

                // Show panel
                panel.style.display = "block";
            } else {
                // Invert button text
                e.target.innerHTML = ogButtonText;

                // Hide panel
                panel.style.display = "none";
            }
        });
    });

    parentCheckboxes.forEach(cb => {
        cb.addEventListener("change", e => {
            const id = e.target.id;
            const panel = document.getElementById(`panel-${id}`);
            const cbs = Array.from(
                panel.getElementsByClassName("cookie-checkbox")
            );

            cbs.forEach(cb => {
                cb.checked = e.target.checked;
            });
        });
    });

    const arrToObject = arr =>
        arr.reduce(
            (acc, key) => {
                return {
                    ...acc,
                    [key]: true
                };
            },
            { required: true }
        );

    const setCookie = value => {
        const {
            name,
            expiryDays,
            domain,
            samesite,
            secure
        } = window.cookieConfig;

        const date = new Date();
        const daysInMs = expiryDays * 24 * 60 * 60 * 1000;
        date.setTime(date.getTime() + daysInMs);

        const cookie =
            `${name}=${JSON.stringify(value)};` +
            `expires=${date.toUTCString()};` +
            `domain=${domain};` +
            `path=/${secure ? ";secure" : ""}` +
            (samesite ? `;samesite=${samesite};` : "");

        document.cookie = cookie;
    };

    const hideDialog = () => {
        cookieDialog.style.display = "none";
    };

    const showDialog = () => {
        cookieDialog.style.display = "block";
    };

    return { hideDialog, showDialog };
})();
